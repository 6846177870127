<template>
  <div v-if="notes">
    <notesList :notes="notes"/>
  </div>
</template>
<script>
export default {
  components: {
    notesList () { return import('@/components/notes/list') }
  },
  props: {
    data: Object
  },
  data () {
    return {
      notes: false
    }
  },
  computed: {

  },
  mounted () {
    this.getNotes()
  },
  methods: {
    getNotes () {
      this.$flix_post({
        data: {
          form: this.data.form,
          entryID: this.data.md5ID,
          user: JSON.parse(this.data.atitle).user
        },
        url: 'notes/get',
        callback: function (ret) {
          if (ret.data[0]) {
            this.notes = ret.data[1]
          }
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
